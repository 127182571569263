import axios from "axios";
import { MICRO_API_URL_USER } from "../config/API_URLS";



class HttpService {
  //gemeco url : validate/username?username=
  validateUserName(user) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
        "userManagement/v1/validate/username?username=" +
        user
      )

      .then((data) => {
        return Promise.resolve(data.data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  validateCompanyName(company) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
        "companyManagement/v2/verify/companyName?name=" +
        company
      )

      .then((data) => {
        return Promise.resolve(data.data.data);
      })
      .catch((err) => Promise.reject(err));
  }

  async register(firstname, lastname, username, email, password) {
    var payload = {
      firstName: firstname,
      lastName: lastname,
      username: username,
      email: email,
      password: password,
    };
    const response = await axios
      .post(MICRO_API_URL_USER.baseURL + "userManagement/v2/user", payload, {
        params: {
          type: "new",
        },
      })
      .catch((err) => Promise.reject(err));
    return response.data;
  }


  getCompany(email) {
    return axios
      .get(
        MICRO_API_URL_USER.baseURL +
        "companyManagement/v1/company/real?email=" +
        email
      )
      .then((data) => {
        return Promise.resolve(data.data);
      })
      .catch((err) => Promise.reject(err));
  }
  changeEmail(payload) {
    return axios.post(
      MICRO_API_URL_USER.baseURL +
      "userManagement/v2/user",
      payload,
      {
        params: {
          type: "update",
        },
      }
    );
  }


  verifySignUpOtp(username, otp) {
    return axios.post(
      MICRO_API_URL_USER.baseURL +
      "userManagement/v1/account/verify",
      {
        username: username,
        otp: otp,
      }
    );
  }
  sendSignUpOtp(username) {
    return axios.post(
      MICRO_API_URL_USER.baseURL +
      "v1/otp", {
      username: username,
      type: "verify",
    });
  }

}
export default new HttpService();
